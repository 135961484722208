import { submitContactForm } from "src/services";
import {
  callicon,
  facebooklogo,
  instagramlogo,
  linkedinlogo,
  locationicon,
  logox,
  mailicon,
  questionicon,
  sign,
  whatsapplogo,
} from "../../assets/index";
import Recaptcha from "../../Components/Recaptcha";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Circles } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Index = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    services: "",
    comment: "",
  });

  useEffect(() => {
    const handleClick = (event) => {
      const targetLink = event.target.closest('a[href="#form-container"]');

      if (targetLink) {
        const target = document.querySelector("#form-container");

        if (target) {
          target.scrollIntoView({ behavior: "smooth" });
       
        }
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);



  const [token, setToken] = useState<string>("");

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (event) => {
    if (errors) {
      setErrors({});
    }
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onCaptchaChange = (value: any) => {
    if (value) {
      setToken(value);
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!token) newErrors.verify = "Please Verify.";
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be digits only.";
    }
    if (!formData.services) newErrors.services = "Please select a service.";
    if (!formData.comment)
      newErrors.comment = "Additional comments are required.";

    return newErrors;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      await Swal.fire({
        title: "Error!",
        text: "Please fix the validation errors.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      setLoading(true);
      setErrors({});
      try {
        const response: any = await submitContactForm(formData);
        if (response) {
          setLoading(false);
          setFormData({
            name: "",
            email: "",
            phone: "",
            services: "",
            comment: "",
          });
          setToken("");
          await Swal.fire({
            title: "Success!",
            text: "Your form has been submitted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        setLoading(false);
        await Swal.fire({
          title: "Error!",
          text: "There was an issue submitting your form. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>
        Contact Us | Ditinus
          Technology
        </title>
      </Helmet>
      <main>
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-6 col-lg-8 col-md-12 col-12 text-center">
                <h1 className="heading-01">Contact Us</h1>
                <p className="p-text">
                  Listen, we’re not exactly mathematicians over here, but we’re
                  experts at solving the equation of success for our clients!
                </p>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-5">
                <a
                  href="#form-container"
                  type="button"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-info">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                <img src={sign} alt="img" />
              </div>
              <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                <h2 className="fs-6 text-WH">Have questions or inquiries?</h2>
                <h4 className="heading-02">
                  Reach out to us for personalized{" "}
                  <span className="text-green">assistance and support.</span>
                </h4>
                <h4 className="heading-06 fw-normal">
                  We’re here to help you every step of the way.
                </h4>
                <h5 className="heading-02 fw-bold">Ditinus Technology,</h5>
                <h5 className="heading-05 fw-normal">
                  E-302, Phase 8A, Vista Tower, Mohali, Punjab
                </h5>
                <div className="media-icon d-flex ">
                  <Link to="/https://www.facebook.com/people/Ditinus_careers/100082887685148/"><img src={facebooklogo} alt="facebook logo" /></Link>
                  <Link to="https://www.instagram.com/ditinus/"><img src={instagramlogo} alt="instagram logo" /></Link>
                 <Link to="https://www.linkedin.com/company/ditinus-technology-pvt-ltd"> <img src={linkedinlogo} alt="linkedin logo" /></Link>
                 <Link to="https://wa.me/+916239382356"> <img src={whatsapplogo} alt="whatsapp logo" /></Link>
                  <Link to="https://x.com/Ditinusmohali"><img src={logox} alt="x logo" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contactForm-section" id="form-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                <form
                  className="contact-form col-inner-box"
                  onSubmit={handleFormSubmit}
                >
                  <div className="col-12 text-center mb-5">
                    <h4 className="heading-04">Let’s Talk</h4>
                    <p className="p-text">Get more done, faster-together</p>
                  </div>
                  <div className="col-12">
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="formInput"
                      value={formData.name}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-12">
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      className="formInput"
                      value={formData.email}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-12">
                    {errors.phone && (
                      <span className="error">{errors.phone}</span>
                    )}
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      className="formInput"
                      value={formData.phone}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-12">
                    {errors.services && (
                      <span className="error">{errors.services}</span>
                    )}
                    <select
                      name="services"
                      className="formInput"
                      value={formData.services}
                      onChange={onChange}
                    >
                      <option value="">Select Services</option>
                      <option value="Design">Design</option>
                      <option value="Development">Development</option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                    </select>
                  </div>

                  <div className="col-12">
                    {errors.comment && (
                      <span className="error">{errors.comment}</span>
                    )}
                    <textarea
                      placeholder="Message"
                      className="formInput"
                      name="comment"
                      value={formData.comment}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-xl-9 col-lg-9 col-md-8 col-12 ">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <button
                          type="submit"
                          className="fill-btn outline-btn2 border-0 text-uppercase"
                          disabled={loading}
                        >
                          {loading ? (
                            <Circles
                              visible={true}
                              height="30"
                              width="30"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass="d-flex justify-content-center"
                              color={"white"}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                      <div className="col-lg-8 col-xl-6 col-md-4 col-sm-12 mt-3">
                        {errors.verify && (
                          <span className="error">{errors.verify}</span>
                        )}
                        <Recaptcha onChange={onCaptchaChange} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-xl-7 col-lg-8 col-md-12 col-12">
                <div className="col-inner-box info-company">
                  <div className="d-flex w-100 align-items-center warning-box ">
                    <img
                      src={questionicon}
                      alt="icon"
                      className="object-fit-contain me-2"
                    />
                    <p className="text-WH mb-2">
                      The fastest way to reach us is to{" "}
                      <span className="text-dark-blue">send us message.</span>{" "}
                    </p>
                  </div>
                  <div>
                    <span className="heading-08 fw-medium">
                      <img
                        style={{ paddingRight: "10px" }}
                        src={mailicon}
                        alt="icon"
                      />
                      Email
                    </span>
                    <p className="heading-08 fw-normal">
                      <strong className="fw-medium">Business Inquiry:</strong>{" "}
                      <a href="mailto:business@ditinustechnology.com">
                        business@ditinustechnology.com
                      </a>
                    </p>
                    <p className="heading-08 fw-normal">
                      <strong className="fw-medium">HR Inquiry:</strong>{" "}
                      <a href="mailto:hr@ditinustechnology.com">
                        hr@ditinustechnology.com
                      </a>
                    </p>
                  </div>
                  <div>
                    <span className="heading-08 fw-medium">
                      <img
                        src={callicon}
                        alt="icon"
                        style={{ paddingRight: "10px" }}
                      />
                      Phone
                    </span>
                    <p className="heading-08 fw-normal">
                      <strong className="fw-medium">Business Phone:</strong>{" "}
                      <a href="callto:+919915578189">+91 991 557 8189</a>
                    </p>
                    <p className="heading-08 fw-normal">
                      <strong className="fw-medium">HR Phone:</strong>{" "}
                      <a href="callto:+916239382356">+91 623 938 2356</a>
                    </p>
                  </div>
                  <div>
                    <span className="heading-08 fw-medium">
                      <img
                        src={locationicon}
                        alt="icon"
                        style={{ paddingRight: "10px" }}
                      />
                      Office Address
                    </span>
                    <p className="heading-08 fw-normal">
                      Mohali <br />
                      <strong className="fw-bold">
                        Ditinus Technology Pvt Ltd.
                      </strong>{" "}
                      <br />
                      E-302, Phase 8A,
                      <br /> Vista Tower,
                      <br /> Mohali, Punjab
                      <br /> India
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="location-map">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13721.732367589504!2d76.67965970711168!3d30.7062228435495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef85bcf88ab3%3A0x520191891f8d816d!2sDitinus%20Technology%20Private%20Ltd.%20Mohali!5e0!3m2!1sen!2sin!4v1724237123562!5m2!1sen!2sin"
                  width="100%"
                  height={600}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
