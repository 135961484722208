import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { caseStudies } from "./data";
import { ux04 } from "src/assets";
import { Helmet } from "react-helmet";

const CaseStudys = () => {
  const { slug } = useParams();
  const [caseStudy, setCaseStudy] = useState(null);
  const [randomCards, setRandomCards] = useState([]);

  useEffect(() => {
    const fetchedCaseStudy = caseStudies.find((study) => study.slug === slug);
    setCaseStudy(fetchedCaseStudy);
    const otherCaseStudies = caseStudies.filter((study) => study.slug !== slug);
    const shuffledStudies = otherCaseStudies.sort(() => 0.5 - Math.random());
    const twoRandomStudies = shuffledStudies.slice(0, 2);
    setRandomCards(twoRandomStudies);
  }, [slug]);

  if (!caseStudy) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>
        {caseStudy?.title ?? "Case Studies"} | Ditinus
          Technology
        </title>
      </Helmet>
      <main className="bg-height4 bg-kpo-bpo our-portfolio1 poortfolio-single-page">
        <section className="subBanner">
          <div className="container">
            <div className="row">
              <h1 className="heading-01">{caseStudy.title}</h1>
            </div>
          </div>
        </section>
        <section className="our-portfoliotext">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <figure>
                  <img
                    className="w-100 "
                    src={caseStudy?.image}
                    alt="Portfolio Details"
                  />
                </figure>
              </div>
            </div>
            <div className="row g-3 pt-3">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">{caseStudy.title}</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
                <p>{caseStudy.description}</p>
              </div>
              <hr style={{ color: "#CCE3FF" }} />
            </div>

            <div className="row g-3 p-ding2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">{caseStudy?.projectRequirement}</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
                <p>{caseStudy?.requirementDes}</p>
              </div>
            </div>

            <div className="row p-ding2 text-WH ">
              {caseStudy.requirements.map((requirement, index) => (
                <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                  <div className="row">
                    <div className="col-1">
                      <img
                        className="img-fluid"
                        src={ux04}
                        alt="Requirement Icon"
                      />
                    </div>
                    <div className="col-11">
                      <p>{requirement}</p>
                    </div>
                  </div>
                </div>
              ))}
              {caseStudy.requirements2.map((requirement, index) => (
                <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                  <div className="row">
                    <div className="col-1">
                      <img
                        className="img-fluid"
                        src={ux04}
                        alt="Requirement Icon"
                      />
                    </div>
                    <div className="col-11">
                      <p>{requirement}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="row g-3 p-ding2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h2 className="heading-02">Solution &amp; Result</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-WH">
                <p>{caseStudy.solution}</p>
              </div>
            </div>

            {/* Right Menu - Two Random Cards */}
            <div className="row g-3 p-ding2">
              <div className="right-menu">
                <div className="row g-4">
                  {randomCards.map((e, index) => (
                    <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                      <Link to={`/case-study/${e.slug}`}>
                        <div className="card">
                          <figure>
                            <img
                              className="w-100"
                              src={e.image2}
                              alt={`Portfolio Image ${index + 1}`}
                            />
                          </figure>
                          <div className="label-tag">
                            {/* <p>
                              <img src={labeltag} alt="Label" /> {e.category}
                            </p> */}
                            <h2>{e.title}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default CaseStudys;
