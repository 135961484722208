import { Link } from "react-router-dom";
import { calendaricon41 } from "src/assets";
import axios from "axios";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import Search from "src/Components/SearchBar";
import RecentPosts from "src/Components/RecentPosts";
import CategoriesList from "src/Components/CategoriesList";
import TagsList from "src/Components/TagsList";
import { Circles } from "react-loader-spinner";
import ComingSoon from "src/Components/ComingSoon";
import { data } from "jquery";
import { Helmet } from "react-helmet";

const IMAGE_URL = process.env.REACT_APP_IMAGE_API_URL;

const Index = () => {
  const [blogs, setBlogs] = useState<any>([]);

  console.log(blogs, "blogsblogsblogsblogsblogsblogsblogs}}}}}}}}}}}}}}}}}}}}");

  const [filteredBlogs, setFilteredBlogs] = useState<any[]>([]);

  console.log(filteredBlogs, "filteredBlogs filteredBlogsfilteredBlogs");

  const [tags, setTags] = useState<any[]>([]);
  const [visibleCount, setVisibleCount] = useState<number>(4);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<{ [key: string]: number }>({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const API_URL = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  const fetchBlogs = async (page) => {
    try {
      const response = await api.get(`/blogs?page=${page}`);
      return response.data; // Ensure this matches your API response
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setError(
        "Failed to fetch blogs. Please check your network connection and API URL."
      );
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const blogData = await fetchBlogs(currentPage);
        console.log(blogData, "blogDatablogDatablogData");

        if (!blogData || !Array.isArray(blogData.data)) {
          throw new Error("Invalid data format");
        }

        setBlogs(blogData?.data);
        setFilteredBlogs(blogData?.data);
        setTotalPages(blogData?.last_page);
      } catch (error) {
        console.error("Fetch error:", error);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleSearch = (query: string) => {
    if (!query) {
      setFilteredBlogs(blogs?.data);
      return;
    }

    const lowercasedQuery = query.toLowerCase();
    const filtered = blogs?.data.filter(
      (blog) =>
        blog.title.toLowerCase().includes(lowercasedQuery) ||
        blog.excerpt.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredBlogs(filtered);
  };

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };
  return (
    <>
      {/* <ComingSoon /> */}
      <Helmet>
        <title>
        Blogs | Ditinus
          Technology
        </title>
      </Helmet>
      <div>
        <main className="bg-height4 bg-kpo-bpo blog-list1">
          <section className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 text-center top-banner">
                <h1 className="heading-01">Our Blog</h1>
                <p className="p-text">
                  Showcasing Success: Explore Our Impactful IT Solutions
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>

      {loading ? (
        <Circles
          height="90"
          width="90"
          color="#0D72B8"
          ariaLabel="circles-loading"
          wrapperClass="justify-content-center mt-5 mb-5"
          visible={true}
        />
      ) : (
        <section className="blog-row">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div
                className="col-lg-8 col-md-12 col-sm-12 "
              >
                <div className="col-lg-8 col-md-7 col-sm-12 ">
                  <div className="row dek-hidden mb-4">
                    <form className="blog-search">
                      <div className="search-box">
                        <input
                          type="text"
                          className="search-input"
                          placeholder="Search....."
                        />
                        <button className="search-button">
                          <i className="bi bi-search" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="blog-list-row">
                  {blogs.map((blog) => (
                    <div key={blog.id} className="blog-item" data-filter="*">
                      <div className="right-menu p-0">
                        <div className="row g-4">
                          <div className="col-12">
                            <div className="card text-WH">
                              <figure>
                                <img
                                  className="w-100"
                                  src={`${IMAGE_URL}${blog.image}`} // Ensure IMAGE_URL is defined
                                  alt={blog.title}
                                />
                              </figure>
                              <div className="label-tag">
                                <p>
                                  <img src={calendaricon41} alt="Date" />
                                  {blog.created_at
                                    ? new Date(
                                        blog.created_at
                                      ).toLocaleDateString()
                                    : "Date not available"}
                                </p>
                              </div>
                              <div className="col-12">
                                <h4 className="heading-04">{blog.title}</h4>
                                <p>{parse(blog.discription)}</p>
                              </div>
                              <div className="btn-readmore">
                                <Link
                                  to={`/blog/${blog.slug}`}
                                  className="transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                                >
                                  Read More{" "}
                                  <i className="bi bi-arrow-up-short ms-1 fs-3" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <nav
                  aria-label="Page navigation"
                  className="d-flex justify-content-center mt-5"
                >
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="side-blog">
                  <Search onSearch={handleSearch} />

                  <RecentPosts
                    blogs={filteredBlogs}
                    visibleCount={visibleCount}
                    onShowMore={handleShowMore}
                    error={error}
                  />
                  {/* <CategoriesList categories={categories} /> */}
                  {/* <TagsList tags={tags} error={error} /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Index;
