import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { careerIcon1, careerIcon2, careerIcon3, careerIcon4 } from "src/assets";
import ComingSoon from "src/Components/ComingSoon";
import { getJobList } from "src/services";
import JobItem from "./component/JobItem";
import Swal from 'sweetalert2'
import { Helmet } from "react-helmet";
const Index = () => {
  const [dataList, setDataList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleJobLoad = async (page: number) => {
    try {
      const response: any = await getJobList(page);
      if (response && response.data) {
        setDataList(response.data);
        console.log(response);
        setTotalPages(response.last_page);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    handleJobLoad(currentPage);
  }, [currentPage]); 

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div>
      <Helmet>
        <title>
        Career | Ditinus
          Technology
        </title>
      </Helmet>
      <main className="bg-height4 bg-kpo-bpo blog-list1">
        <section className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 text-center top-banner">
              <h1 className="heading-01">Career</h1>
              <p className="p-text">
                At Ditinus Technologies, we are passionate about innovation and
                excellence. We believe that our success is driven by the
                talented and dedicated people who make up our team. If you are
                eager to be part of a forward-thinking company that values
                creativity and collaboration, we want to hear from you!
              </p>
            </div>
          </div>
        </section>
        <section className="our-core">
          <div className="container">
            <div className="row mt-2">
              <h2 className="heading-02 lh-sm text-center text-WH">
                Our Core Values
              </h2>
            </div>
            <div className="row">
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={careerIcon1} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">Growth Mindset</h5>
                  <p className="text-WH p-text">
                    We grow by accepting challenges that stretch us outside of
                    our comfort zone. These experiences shape us, and we come
                    out of them stronger.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={careerIcon2} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">
                    Lead With Empathy
                  </h5>
                  <p className="text-WH p-text">
                    We work from a place of empathy first in everything we do.
                    It’s how we build our product, support our customers, and
                    treat each other here at Uscreen.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={careerIcon3} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">Dream Big</h5>
                  <p className="text-WH p-text">
                    We’ve made it this far because we dream big! We talk about
                    “big hairy audacious goals”, formulate plans to achieve
                    them, and support each other along the way.
                  </p>
                </div>
              </div>
              <div
                className="col-12 col-xl-3 col-lg-3 col-md-6 serviceBox"
                data-aos="fade-up"
              >
                <div className="servicesContentBox">
                  <div className="img-icon" data-aos="fade-left">
                    <img src={careerIcon4} alt="icon" />
                  </div>
                  <h5 className="p-text18 fw-bold text-WH">Push The Pace</h5>
                  <p className="text-WH p-text">
                    We play to win. That means raising our standards, increasing
                    velocity, narrowing our focus and pushing the pace.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="open-roles">
          <div className="container">
            <div className="row mt-2 text-center">
              <h2 className="heading-02 lh-sm text-WH">Open Roles</h2>
              <h4 className="heading-08">Join our Talent Community</h4>
            </div>

            {dataList.data &&
              dataList?.data?.map((item: any, index: number) => (
                <JobItem key={index} job={item} />
              ))}
          </div>
          <nav
            aria-label="Page navigation"
            className="d-flex justify-content-center  mt-5"
          >
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {[...Array(totalPages)].map((_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </section>
      </main>
    </div>
  );
};

export default Index;
